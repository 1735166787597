<template>
  <ul class="menu-nav">
    <!-- Dashboard -->

    <!-- Dashboard -->

    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <li
      class="menu-section"
      v-if="hasAccessToMenuItem('Ver modulo de catalogos')"
    >
      <h4 class="menu-text">Cátalogos</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- 00000000000000000000000000000000000000000 -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      v-if="hasAccessToMenuItem('Desplegar menu general')"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/general'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-circle-vol-2"></i>
        <span class="menu-text">General</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">General</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/academic_levels"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Niveles académicos')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Niveles académicos</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/education_departments"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Departamentos educativos')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Departamentos educativos</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/sections"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Secciones')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Secciones</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/specialities"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Especialidades')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Especialidades</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/groups"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Grupos')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Grupos</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/grades"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Grados')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Grados</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/student_associations"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="false"
          >
            <!-- <router-link
            to="/student_associations"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('student_associations')"
          > -->
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Asociaciones estudiantiles</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
        </ul>
      </div>
    </li>

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- 00000000000000000000000000000000000000000 -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu gestion academica')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/academic_managment'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-circle-vol-2"></i>
        <span class="menu-text">Gestión académica</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Gestión académica</span>
            </span>
          </li>

          <router-link
            to="/stage_types"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Tipos de etapa')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tipos de etapa</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/stages"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Etapas')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Etapas</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/subject_evaluation_scales"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Escalas de evaluación')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Escalas de evaluación</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/activity_categories"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Agrupaciones de perfil')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Agrupaciones de perfil</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/activity_types"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Tipos de actividad')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tipos de actividad</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/evaluation_types"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Tipos de evaluación')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tipos de evaluación</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/subject_types"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Tipos de asignatura')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tipos de asignatura</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/subjects"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Asignaturas')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Asignaturas</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/teacher_subjects"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Mis asignaturas')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Mis asignaturas</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/evaluations"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Perfiles')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Perfiles</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/request_reasons"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Razones de solicitud')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Razones de solicitud</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/my_requests"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Mis solicitudes')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Mis solicitudes</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/requests"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Solicitudes')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Solicitudes</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- 00000000000000000000000000000000000000000 -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu gestion estudiantil')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/student_managment'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-circle-vol-2"></i>
        <span class="menu-text">Gestión estudiantil</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Gestión estudiantil</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/students"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Estudiantes')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Estudiantes</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/associate_students"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="false"
          >
            <!-- <router-link
            to="/student_associations"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('student_associations')"
          > -->
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Asociar estudiantes</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/group_assignments"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Asignación de grupos')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Asignación de grupos</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/reports"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Reportes gestión estudiantil')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reportes</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/events"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Administrar propiedades de eventos')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Eventos</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
        </ul>
      </div>
    </li>

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- 00000000000000000000000000000000000000000 -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu matricula')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/enrollment'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-circle-vol-2"></i>
        <span class="menu-text">Matrícula</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Matrícula</span>
            </span>
          </li>

          <router-link
            v-if="
              hasAccessToMenuItem('Generar reportes de proceso de matrícula')
            "
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            to="/enrollment_reports"
          >
            <li
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              aria-haspopup="true"
              class="menu-item mt-n5"
              data-menu-toggle="hover"
            >
              í
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reportes</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/enrollment_processes"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Proceso de matrícula')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Proceso de matrícula</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/manage_students_families"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="
              hasAccessToMenuItem(
                'Gestionar responsables en proceso de matrícula'
              )
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Gestionar responsables</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <li
      class="menu-section"
      v-if="hasAccessToMenuItem('Ver modulo de diario pedagogico')"
    >
      <h4 class="menu-text">Diario pedagógico</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- begin::Gestión de disciplina -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu gestion de disciplina')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pedagogical_diary'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-group"></i>
        <span class="menu-text">Gestión de disciplina</span>

        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Gestión de disciplina</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->

          <!-- ----------------------------------------- -->
          <router-link
            to="/assign_code"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Asignar Códigos')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Asignar código</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <router-link
            to="/lateness"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Llegadas tardes')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Asignar llegada tarde</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/create_observation"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Observaciones')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Crear observación</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->

          <!-- ----------------------------------------- -->
        </ul>
      </div>
    </li>
    <!-- end::Gestión de disciplina -->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- begin::Gestión de faltas -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu gestion de faltas')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pedagogical_diary'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-time"></i>
        <span class="menu-text">Gestión de faltas</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Gestión de faltas</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/justifications"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Justificaciones')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Justificación de faltas</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/permissions"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Permisos')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Creación de permisos</span
                ><!-- TABLA: SCHEDULES -->
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
        </ul>
      </div>
    </li>
    <!-- end::Gestión de faltas -->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- begin::Disciplina y reportes -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu reportes disciplinarios')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pedagogical_diary'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-user-1"></i>
        <span class="menu-text">Reportes disciplinarios</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Reportes disciplinarios</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->
          <router-link
            to="/discipline_dashboard"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Panel disciplinario')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Panel disciplinario</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/discipline_reports"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Panel disciplinario')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Reportes disciplinarios</span>
              </a>
            </li>
          </router-link>
          <!----------------------------------------------->
        </ul>
      </div>
    </li>
    <!-- end::Disciplina y reportes-->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- begin::ASISTENCIA -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu asistencia')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pedagogical_diary'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-writing"></i>
        <span class="menu-text">Asistencia</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Asistencia</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->
          <router-link
            to="/student_class"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Asistencia')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Lista de asistencia</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <router-link
            to="/student_class_record"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Historial de asistencia')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Historial Asistencia</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
        </ul>
      </div>
    </li>
    <!-- end::ASISTENCIA -->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- begin::HORARIOS -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu horarios')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pedagogical_diary'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-calendar-6"></i>
        <span class="menu-text">Horarios</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Horarios</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/daily_block"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Horas clase')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Bloques y horas</span
                ><!-- TABLA: daily_block_details -->
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <router-link
            to="/schedules"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Horarios')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Gestión de horarios</span
                ><!-- TABLA: SCHEDULES -->
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <router-link
            to="/teachers-schedules"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Horarios de docentes')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Horarios de docentes</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <!-- end::HORARIOS -->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- begin::CÓDIGOS -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu codigos')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pedagogical_diary'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-list-2"></i>
        <span class="menu-text">Códigos</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Códigos</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->
          <router-link
            to="/codes"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Códigos')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Listado de códigos</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
        </ul>
      </div>
    </li>
    <!-- end::CÓDIGOS -->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- begin::INSTALACIONES -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu  instalaciones')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pedagogical_diary'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-position"></i>
        <span class="menu-text">Instalaciones</span>

        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Instalaciones</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/zones"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Zonas')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Zonas</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/areas"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Areas')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Áreas</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
          <router-link
            to="/locals"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Locales')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Locales</span>
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->
          <!-- ----------------------------------------- -->
        </ul>
      </div>
    </li>
    <!-- end::INSTALACIONES -->

    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <li
      class="menu-section"
      v-if="hasAccessToMenuItem('Ver modulo de enfermeria')"
    >
      <h4 class="menu-text">Unidad de primeros auxilios</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

    <!-- 00000000000000000000000000000000000000000 -->
    <!-- begin::Dashboard de unidad de primeros auxilios -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="hasAccessToMenuItem('Desplegar menu de enfermeria')"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pedagogical_diary'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-group"></i>
        <span class="menu-text">Visitas unidad de primeros auxilios</span>

        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Visitas unidad de primeros auxilios</span>
            </span>
          </li>
          <!-- ----------------------------------------- -->

          <!-- ----------------------------------------- -->
          <router-link
            to="/clinical_nursing"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="true"
          >
            <!-- <router-link
            to="/assign_code"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
            v-if="hasAccessToMenuItem('Gestionar enfermeria')"
          > -->
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text"
                  >Gestión de unidad de primeros auxilios</span
                >
              </a>
            </li>
          </router-link>
          <!-- ----------------------------------------- -->

          <!-- ----------------------------------------- -->
        </ul>
      </div>
    </li>
    <!-- end::Gestión de disciplina -->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
export default {
  name: "KTMenu",
  mounted() {
    this.$store.dispatch(
      GET_CURRENT_PAGE_ACTIONS,
      "Disponibilidad de modulos y vistas de sistema"
    );
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hasAccessToMenuItem(section) {
      return this.existInArray(section, this.permissions);
    },
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapGetters(["currentPageActions"]),
  },
};
</script>
