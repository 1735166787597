<template>
  <div class="topbar-item">
    <div
      v-if="hasAccessToMenuItem('Ajustes')"
      class="pa-1 px-3 rounded-lg blue lighten-5 d-flex flex-column flex-sm-row justify-center align-center mr-3 ml-2"
    >
      <v-progress-circular
        v-if="fetchingUserStablishedYear"
        indeterminate
        color="blue"
        :width="3"
        :size="20"
      ></v-progress-circular>

      <span
        v-if="!fetchingUserStablishedYear"
        class="font-weight-medium blue--text"
        >Está consultando</span
      >
      <span
        v-if="!fetchingUserStablishedYear"
        class="font-weight-bold text-h6 blue--text ml-sm-1"
        >{{
          originalUserSetting !== null
            ? originalUserSetting
            : new Date().getFullYear()
        }}</span
      >
    </div>
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hola,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUserPersonalInfo.first_name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img
          v-if="false"
          alt="Pic"
          :src="`https://ricalapis.ricaldone.edu.sv/core_api/public/resources/images/${currentUserPersonalInfo.photo}`"
        />
        <span
          v-if="currentUserPersonalInfo.first_name"
          class="symbol-label font-size-h5 font-weight-bold"
        >
          {{ getFirstNameInitial }}
        </span>
      </span>
    </div>

    <!-- content -->
    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <v-row class="mt-8 mt-lg-2">
        <v-col class="d-flex align-center" cols="9"
          ><p class="mb-0 font-weight-bold text-h5">Perfíl de usuario</p></v-col
        >
        <v-col class="d-flex justify-end offcanvas-header" cols="3">
          <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-danger"
            id="kt_quick_user_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i> </a
        ></v-col>
      </v-row>

      <v-row>
        <!-- photo card -->
        <v-col class="d-flex justify-center" cols="12">
          <div class="symbol symbol-100">
            <div
              class="symbol-label font-size-h1 font-weight-bold bg-blue text-black"
            >
              {{ getFirstNameInitial }}
            </div>
          </div>
        </v-col>
        <!-- user type -->
        <v-col class="d-flex justify-center mt-n1" cols="12">
          <v-chip
            outlined
            :class="`ma-2`"
            :text-color="getUserType.color"
            :color="getUserType.color"
            pill
          >
            <span class="font-weight-bold">{{ getUserType.name }}</span>
            <v-icon right> mdi-{{ getUserType.icon }} </v-icon>
          </v-chip>
        </v-col>
        <!-- name and email -->
        <v-col cols="12">
          <p
            class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary text-center mb-n1"
          >
            {{ getFullName }}
          </p>
          <div class="navi mt-2">
            <a href="#" class="navi-item">
              <span class="mx-auto navi-link p-0 pb-2">
                <span
                  class="navi-text text-muted text-hover-primary text-center"
                >
                  {{ currentUserPersonalInfo.email }}
                </span>
              </span>
            </a>
          </div>
        </v-col>
      </v-row>

      <!-- Settings button -->
      <v-row class="mt-5" v-if="hasAccessToMenuItem('Ajustes')">
        <v-col class="d-flex justify-center" cols="12">
          <button
            class="btn btn-light-primary btn-bold"
            @click="showMdlSettings()"
          >
            <v-icon left> mdi-cog </v-icon>
            Ajustes de usuario
          </button>
        </v-col>
      </v-row>

      <!-- "regresar a core" button -->
      <v-row class="mt-15 pt-10">
        <v-col class="d-flex justify-center" cols="12">
          <button class="btn btn-light-warning btn-bold" @click="onLogout">
            <v-icon>mdi-arrow-left-circle-outline</v-icon>
            Cerrar sesión y regresar a
            <span class="font-weight-bold">CORE</span>
          </button>
        </v-col>
      </v-row>
    </div>

    <!-- settings modal -->
    <SettingsModal
      ref="mdlSettings"
      @refresh="gettingUserSetYear()"
      :userStablishedYear="userStablishedYearData"
    ></SettingsModal>
    <SetInitialYearModal ref="mdlFirst"></SetInitialYearModal>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import JwtService from "@/core/services/jwt.service";
import settingsRepository from "@/repositories/settingsRepository";
import SettingsModal from "@/components/modals/settings/ChangeSettings.vue";
import SetInitialYearModal from "@/components/modals/settings/SetInitialYear.vue";

export default {
  name: "KTQuickUser",
  components: {
    SettingsModal,
    SetInitialYearModal,
  },
  data() {
    return {
      userStablishedYear: null,
      userStablishedYearData: null,
      originalUserSetting: null,
      fetchingUserStablishedYear: false,
      hasValidUserId: false,
    };
  },

  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Ajustes");
  },

  watch: {
    "currentUserPersonalInfo.id_user"(newValue) {
      if (!!newValue) {
        //this.openMainModal();
        this.gettingUserSetYear();
      }
    },
  },

  methods: {
    gettingUserSetYear() {
      this.fetchingUserStablishedYear = true;
      this.hasValidUserId = true;
      settingsRepository
        .getUserSettings(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
          this.originalUserSetting = data.setting;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title:
              "no se pudo obtener el año establecido previamente por el usuario",
          });

          this.hasValidUserId = false;
        })
        .finally(() => {
          this.fetchingUserStablishedYear = false;
        });
    },
    hasAccessToMenuItem(section) {
      return this.existInArray(section, this.permissions);
    },
    showMdlSettings() {
      this.$refs.mdlSettings.toggleSettingsModal();
    },
    openMainModal() {
      settingsRepository
        .getUserSettings(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
          this.userStablishedYear = data;
          if (!this.userStablishedYear) {
            if (this.hasAccessToMenuItem("Ajustes")) {
              this.$refs.mdlFirst.toggleSettingsModal();
            }
          }
          this.userStablishedYearData = { ...this.userStablishedYear };
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title:
              "no se pudo obtener el año establecido previamente por el usuario",
          });
        });
    },
    onLogout() {
      const token = JwtService.getToken();
      this.$store.dispatch(LOGOUT).then(() => {
        window.location = `http://core.ricaldone.edu.sv/core/${token}`;
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    ...mapGetters([
      "currentUserPersonalInfo",
      "userType",
      "permissions",
      "currentPageActions",
    ]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.first_name +
        " " +
        this.currentUserPersonalInfo.last_name
      );
    },

    getUserType() {
      let userType =
        this.userType?.user_type != undefined ? this.userType.user_type : "";

      //User type personalization
      if (userType == "Superadmin") {
        return {
          color: "success",
          name: userType,
          icon: "alien-outline",
          showSelectedYear: true,
        };
      } else if (userType == "Superadministrador") {
        return {
          color: "deep-orange darken-1",
          name: userType,
          icon: "shield-account",
          showSelectedYear: true,
        };
      } else {
        return {
          color: "primary",
          name: userType,
          icon: "account-outline",
          showSelectedYear: false,
        };
      }
    },

    getFirstNameInitial() {
      return "first_name" in this.currentUserPersonalInfo
        ? this.currentUserPersonalInfo.first_name.charAt(0).toUpperCase()
        : "";
    },
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
