<template>
  <v-dialog
    scrollable
    v-model="settingsDialogState"
    transition="dialog-bottom-transition"
    max-width="650"
    persistent
  >
    <v-card>
      <v-card-title dark color="blue-grey darken-4" class="elevation-0 pa-7">
        <v-row class="">
          <v-col class="d-flex align-center" cols="9"
            ><p class="mb-0 font-weight-bold text-h4">
              Debes seleccionar un año
            </p></v-col
          >
          <!-- <v-col class="d-flex justify-end offcanvas-header" cols="3">
            <v-btn
              :loading="savingAnimation"
              :disabled="savingAnimation"
              color="red"
              class="ma-2 white--text"
              icon
              small
              @click="saveChanges()"
            >
              <v-icon dark>
                mdi-close-circle
              </v-icon>
            </v-btn>
          </v-col> -->
        </v-row>
      </v-card-title>
      <v-card-text class="">
        <v-row class="mt-2">
          <v-alert
            color="warning"
            class="mx-3"
            dense
            outlined
            prominent
            text
            icon="mdi-calendar-cursor"
          >
            <p class="text-body-1 font-weight-medium mb-0">
              A partir de 2023,
              <span class="font-weight-bold"
                >puedes consultar la información del año electivo 2022 y 2023 de
                los estudiantes y docentes</span
              >. Es por eso, que hemos añadido una sección de ajustes en la
              sección de usuario para que puedas realizar el cambio.
            </p></v-alert
          >

          <p
            class="text-center text-body-2 font-weight-bold red--text mb-4 px-5"
          >
            *Siempre puede cambiar el año en cualquier momento en la sección de
            ajustes que está en el menú despegable con la información de usuario
            donde cierra sesión.
          </p>

          <v-row class="px-3 pt-3">
            <v-col cols="6">
              <v-card
                :disabled="this.selectedYear.setting == '2023'"
                class="rounded-lg"
              >
                <v-row>
                  <v-col cols="12">
                    <div class="d-flex align-center rounded-lg">
                      <div
                        class="card-icon-container purple lighten-4 d-flex align-center justify-center rounded-circle pa-3 mx-auto"
                      >
                        <v-icon color="purple" large
                          >mdi-sort-calendar-descending</v-icon
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="font-weight-bolder text-h4 mb-0 text-center">
                      2022
                    </p>
                  </v-col>
                </v-row>

                <v-card-actions class="mt-n8">
                  <v-container>
                    <v-checkbox
                      v-model="selectedYear.setting"
                      label=""
                      color="purple"
                      value="2022"
                      hide-details
                    >
                      <template v-slot:label>
                        <div class="mt-3">
                          <p class="text-body-2 mb-0">
                            <span class="font-weight-bold"
                              >Continuar con
                              <span class="purple--text"> 2022</span></span
                            >, y no volver a mostrar este aviso.
                          </p>
                        </div>
                      </template></v-checkbox
                    >
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card
                :disabled="this.selectedYear.setting == '2022'"
                class="rounded-lg"
              >
                <v-row>
                  <v-col cols="12">
                    <div class="d-flex align-center rounded-lg">
                      <div
                        class="card-icon-container green lighten-4 d-flex align-center justify-center rounded-circle pa-3 mx-auto"
                      >
                        <v-icon color="green" large
                          >mdi-sort-calendar-ascending</v-icon
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="font-weight-bolder text-h4 mb-0 text-center">
                      2023
                    </p>
                  </v-col>
                </v-row>

                <v-card-actions class="mt-n8">
                  <v-container>
                    <v-checkbox
                      v-model="selectedYear.setting"
                      label
                      color="green"
                      value="2023"
                      hide-details
                    >
                      <template v-slot:label>
                        <div class="mt-3">
                          <p class="text-body-2 mb-0">
                            <span class="font-weight-bold"
                              >Continuar con
                              <span class="green--text"> 2023</span></span
                            >, y no volver a mostrar este aviso.
                          </p>
                        </div>
                      </template></v-checkbox
                    >
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-2" v-if="!!selectedYear.setting">
        <v-row class="my-1">
          <v-col cols="12">
            <div class="text-center">
              <v-btn
                @click="saveChanges()"
                :loading="isSavingSettings"
                rounded
                color="success"
                dark
              >
                Continuar
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
              <p class="mb-2 mt-2 text-body-2">
                Se mostrarán datos del año
                <span class="font-weight-bold">{{ selectedYear.setting }}</span
                >.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import settingsRepository from "@/repositories/settingsRepository";

export default {
  name: "SetInitialYearModal",

  data() {
    return {
      settingsDialogState: false,
      isLoadingYears: false,
      availableYears: [],
      savingAnimation: false,
      selectedYear: {
        setting: null,
      },
      isSavingSettings: false,
    };
  },

  methods: {
    toggleSettingsModal() {
      this.settingsDialogState = !this.settingsDialogState;
    },

    saveChanges() {
      this.isSavingSettings = true;

      settingsRepository
        .saveUserSettings(
          this.currentUserPersonalInfo.id_user,
          this.selectedYear
        )
        .then(() => {
          this.toggleSettingsModal();
          this.fireToast({
            icon: "success",
            title: "Ajustes actualizados correctamente",
          });
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isSavingSettings = false;
        });
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo", "userType"]),
  },
};
</script>

<style>
.card-icon-container {
  aspect-ratio: 1/1;
  height: 100%;
  min-height: 80px !important;
}
</style>
