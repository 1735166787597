<template
  ><v-app>
    <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
      <DebugModeBanner> </DebugModeBanner>
      <!-- begin:: Header Mobile -->
      <KTHeaderMobile></KTHeaderMobile>
      <!-- end:: Header Mobile -->

      <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

      <!-- begin::Body -->
      <div class="d-flex flex-row flex-column-fluid page">
        <!-- begin:: Aside Left -->
        <KTAside v-if="asideEnabled"></KTAside>
        <!-- end:: Aside Left -->

        <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
          <!-- begin:: Header -->
          <KTHeader></KTHeader>
          <!-- end:: Header -->

          <!-- begin:: Content -->
          <div
            id="kt_content"
            class="content d-flex flex-column flex-column-fluid"
          >
            <!-- begin:: Content Head -->

            <!-- begin:: Content Head -->
            <KTSubheader
              v-if="subheaderDisplay"
              v-bind:breadcrumbs="breadcrumbs"
              v-bind:title="pageTitle"
            />
            <!-- end:: Content Head -->

            <!-- begin:: Content Body -->
            <div class="d-flex flex-column-fluid">
              <div
                :class="{
                  'container-fluid': contentFluid,
                  container: !contentFluid,
                }"
              >
                <transition name="fade-in-up">
                  <router-view />
                </transition>
              </div>
            </div>
          </div>
          <KTFooter></KTFooter>
        </div>
      </div>
      <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
      <KTScrollTop></KTScrollTop></div
  ></v-app>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import DebugModeBanner from "@/view/layout/banner/DebugModeBanner";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import {
  VERIFY_TOKEN,
  VERIFY_AUTH,
} from "@/core/services/store/auth.module.js";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader,
    DebugModeBanner,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());

    this.verifyToken();
  },
  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {
    verifyToken() {
      const token = this.$route.params.token;
      if (token && token.length == 40) {
        this.$store
          .dispatch(VERIFY_TOKEN, token)
          .then(() => {
            this.$router.push({ name: "dashboard" });
          })
          .catch((err) => {
            if ("response" in err && !err.response) {
              this.sweetAlertResponse(data);
            } else {
              window.location = `https://core.ricaldone.edu.sv/core/${token}`;
            }
          });
      } else if (JwtService.getToken()) {
        this.$store.dispatch(VERIFY_AUTH);
      } else {
        if (!this.isAuthenticated) {
          this.$router.push({ name: "unauthenticated" });
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$font-family: "Montserrat";

.v-application {
  [class*="text-"] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

.v-list-item__title {
  font-size: 1.1rem !important;
}

.v-list-item__subtitle {
  font-size: 1.05rem !important;
}

// Table
table thead tr th {
  font-size: 1rem !important;
}

table.v-data-table {
  max-width: 100% !important;
}

table.v-table tbody td {
  font-size: 1rem !important;
}

.v-data-table__wrapper .text-start {
  white-space: nowrap !important;
}

.v-data-footer {
  font-size: 0.95rem !important;
}

// Card
.card-label {
  font-size: 1.4rem !important;
}

.card-label span {
  font-size: 1.05rem;
}
</style>
