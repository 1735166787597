<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    class="max-width-280"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="btn btn-icon btn-clean btn-lg mr-1 my-auto pulse pulse-primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-badge :value="newNotification" color="red" dot>
          <v-icon color="blue">
            mdi-bell
          </v-icon>
        </v-badge>
        <span class="pulse-ring"></span>
      </div>
    </template>

    <v-card class="max-width-280">
      <v-card-title
        class="grey darken-4 accent-4 px-10 pt-8 pb-4 d-flex flex-row justify-center align-center"
      >
        <div class="text-h5 white--text">
          Notificaciones
        </div>

        <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2 mr-0"
          @click="
            getScoresCorrectionAnsweredRequests,
              getLimitDateExtensionAnsweredRequests
          "
        >
          {{ getNumOfNotifications }}
        </span>
      </v-card-title>

      <v-tabs
        v-model="tabIndex"
        dark
        background-color="grey darken-4 accent-4"
        show-arrows
        height="35"
        centered
      >
        <v-tabs-slider color="teal accent-3"></v-tabs-slider>

        <v-tab>
          Correcciones de nota
        </v-tab>
        <v-tab>
          Extensiones de perfil
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabIndex">
        <!-- begin::Scores Amend List Tab -->
        <v-tab-item>
          <perfect-scrollbar
            class="scroll px-5 py-3"
            style="max-height: 40vh; position: relative;"
            v-if="scoresCorrectionAnswered.length > 0"
          >
            <template v-for="(request, i) in scoresCorrectionAnswered">
              <div :key="i">
                <v-list-item
                  class="list-item px-0"
                  color="grey lighten-1"
                  bg
                  three-line
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-dark mb-1 font-size-lg text-wrap"
                    >
                      <span class="font-weight-bold">
                        {{ request.user_who_has_reviewed_full_name }}
                      </span>
                      ha procesado tu solicitud de
                      <span class="text-lowercase font-weight-bold">
                        {{ request.request_clasification }}
                      </span>
                      .
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text--grey text--darken-3 mb-2 text-wrap"
                    >
                      {{ request.created_at }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-btn
                        outlined
                        color="grey darken-4"
                        small
                        @click="goToMyRequests(request)"
                      >
                        Ver detalles
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  class="mb-0 mt-1"
                  v-if="i < scoresCorrectionAnswered.length - 1"
                  :key="i"
                ></v-divider>
              </div>
            </template>
          </perfect-scrollbar>
          <div
            class="d-flex flex-center text-center text-muted min-h-200px"
            v-else
          >
            ¡Todo al dia! <br />No hay notificaciones nuevas.
          </div>
        </v-tab-item>
        <!-- end::Scores Amend List Tab -->
        <!-- begin::Limit Date Extension List Tab -->
        <v-tab-item>
          <perfect-scrollbar
            class="scroll px-5 py-3"
            style="max-height: 40vh; position: relative;"
            v-if="limitDateExtensionAnswered.length > 0"
          >
            <template v-for="(request, i) in limitDateExtensionAnswered">
              <div :key="i">
                <v-list-item
                  class="list-item px-0"
                  color="grey lighten-1"
                  bg
                  three-line
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-dark mb-1 font-size-lg text-wrap"
                    >
                      <span class="font-weight-bold">
                        {{ request.user_who_has_reviewed_full_name }}
                      </span>
                      ha procesado tu solicitud de
                      <span class="text-lowercase font-weight-bold">
                        {{ request.request_clasification }}
                      </span>
                      .
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text--grey text--darken-3 mb-2 text-wrap"
                    >
                      {{ request.created_at }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-btn
                        color="grey darken-4 mr-2 mb-2"
                        dark
                        small
                        @click="goToEvaluation(request)"
                      >
                        Ver perfil de la evaluación
                      </v-btn>
                      <v-btn
                        outlined
                        color="grey darken-4"
                        small
                        @click="goToMyRequestsAndOpenLimitDateRequest(request)"
                      >
                        Ver solicitud
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  class="mb-0 mt-1"
                  v-if="i < limitDateExtensionAnswered.length - 1"
                  :key="i"
                ></v-divider>
              </div>
            </template>
          </perfect-scrollbar>
          <div
            class="d-flex flex-center text-center text-muted min-h-200px"
            v-else
          >
            ¡Todo al dia! <br />No hay notificaciones nuevas.
          </div>
        </v-tab-item>
        <!-- end::Limit Date Extension List Tab -->
      </v-tabs-items>
    </v-card>
  </v-menu>
</template>

<script>
import requestRepository from "@/repositories/requestRepository";
import limitDateExtensionRepository from "@/repositories/limitDateExtensionRepository";
import { mapGetters } from "vuex";

export default {
  name: "TeacherDropdownMenu",
  data() {
    return {
      menu: false,
      tabIndex: null,
      scoresCorrectionAnswered: [],
      limitDateExtensionAnswered: [],
      newNotification: false,
    };
  },
  created() {
    // TODO: Because of the cnage made related with the db schemas implementation this is not longer working, this might be updated with a proper implementation of sockets
    // this.connectToBroadcastingChannels();
    // this.getScoresCorrectionAnsweredRequests();
    // this.getLimitDateExtensionAnsweredRequests();
  },
  destroyed() {
    // TODO: Because of the cnage made related with the db schemas implementation this is not longer working, this might be updated with a proper implementation of sockets

    // this.disconnectToBroadcastingChannels();
  },
  methods: {
    getScoresCorrectionAnsweredRequests() {
      // Si el id_user no esta setteado no se hace la petición
      if (!this.currentUserPersonalInfo.id_user) {
        return;
      }
      requestRepository
        .getWhereLastTrackingIsAcceptedOrRejectedByUserId(
          this.currentUserPersonalInfo.id_user
        )
        .then(({ data }) => {
          this.scoresCorrectionAnswered = data;
        })
        .catch((err) => {
          // Sweetalert that indicates the operation cannot be completed
          console.error("scores corrections were not retrieved");
        });
    },
    getLimitDateExtensionAnsweredRequests() {
      // Si el id_user no esta setteado no se hace la petición
      if (!this.currentUserPersonalInfo.id_user) {
        return;
      }
      limitDateExtensionRepository
        .getWhereLastTrackingIsAcceptedOrRejectedByUserId(
          this.currentUserPersonalInfo.id_user
        )
        .then(({ data }) => {
          this.limitDateExtensionAnswered = data;
        })
        .catch((err) => {
          console.error("limit date extension requests were not retrieved");
        });
    },
    goToMyRequests({ idE }) {
      if (
        this.$router.history.current.path === "/my_requests" &&
        this.$router.history.current.query.r === idE
      ) {
        return;
      } else {
        this.$router.push({
          path: "my_requests",
          query: {
            r: idE,
            lde: null,
          },
        });
      }
    },
    goToMyRequestsAndOpenLimitDateRequest({ idE }) {
      if (
        this.$router.history.current.path === "/my_requests" &&
        this.$router.history.current.query.lde === idE
      ) {
        return;
      } else {
        this.$router.push({
          path: "my_requests",
          query: {
            lde: idE,
            r: null,
          },
        });
      }
    },
    goToEvaluation({ evaluation_id, subject_id }) {
      if (
        this.$router.history.current.path === "/evaluation" &&
        this.$router.history.current.query.e == evaluation_id
      ) {
        return;
      } else {
        this.$router.push({
          path: "evaluation",
          query: {
            e: evaluation_id,
            s: subject_id,
            f: "subject_evaluations",
          },
        });
      }
    },
    connectToBroadcastingChannels() {
      try {
        // TODO: Remove commented code, and replace it with the another socker implementation
        // window.Echo.private("requests.answered").listen(
        //   "ScoresCorrectionAnswered",
        //   (response) => {
        //     if (
        //       response.request.user_id_who_made_request ===
        //       this.currentUserPersonalInfo.id_user
        //     ) {
        //       this.scoresCorrectionAnswered.unshift(response.request);
        //       this.newNotification = true;
        //     }
        //   }
        // );
        // window.Echo.private("limit-date-extensions.answered").listen(
        //   "SubEvaluationLimitDateExtensionAnswered",
        //   (response) => {
        //     if (
        //       response.request.user_id_who_made_request ===
        //       this.currentUserPersonalInfo.id_user 
        //     ) {
        //       this.limitDateExtensionAnswered.unshift(response.request);
        //       this.newNotification = true;
        //     }
        //   }
        // );
      } catch (error) {
        console.error(error);
      }
    },
    disconnectToBroadcastingChannels() {
      try {
        // TODO: Remove commented code, and replace it with the another socker implementation
        // window.Echo.leave("requests.answered");
        // window.Echo.leave("limit-date-extensions.answered");
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(["userType", "currentUserPersonalInfo"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    getUserType() {
      const { user_type: userType } = this.userType;
      return userType;
    },
    getUserId() {
      return this.currentUserPersonalInfo.id_user;
    },
    getNumOfNotifications() {
      return (
        this.scoresCorrectionAnswered.length +
        this.limitDateExtensionAnswered.length
      );
    },
  },
  watch: {
    getUserId(newValue, oldValue) {
      // Si el id_user no tenia ningun valor se observa a cuando cambie y se  hace la peticón
      if (newValue) {
    // TODO: Because of the cnage made related with the db schemas implementation this is not longer working, this might be updated with a proper implementation of sockets

        // this.getScoresCorrectionAnsweredRequests();
        // this.getLimitDateExtensionAnsweredRequests();
      }
    },
    menu(newValue, oldValue) {
      // Al abrirse el menu el badge se oculta
      if (newValue) {
        this.newNotification = false;
      }
    },
  },
};
</script>

<style scoped>
.text-wrap {
  white-space: normal !important;
}
</style>
