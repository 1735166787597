<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">Notificaciones</span>
        <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
          @click="getScoresCorrectionAnsweredRequests"
        >
          {{ scoresCorrectionRequested.length }}
        </span>
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Alertas
          </a>
        </li>
        <!-- <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="1"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Events
          </a>
        </li>
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="2"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Logs
          </a>
        </li> -->
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <!-- begin::Alerts tab -->
      <b-tab active class="p-8">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative;"
          v-if="scoresCorrectionRequested.length > 0"
        >
          <template v-for="(request, i) in scoresCorrectionRequested">
            <!--begin::Item-->
            <div
              :class="{ 'd-flex align-items-center mb-6': true }"
              v-bind:key="i"
            >
              <!--begin::Text-->
              <div class="d-flex flex-column justify-start align-start">
                <p class="text-dark mb-1 font-size-lg">
                  <span class="font-weight-bold">
                    {{ request.user_full_name }}
                  </span>
                  ha solicitado una
                  <span class="text-lowercase font-weight-bold">
                    {{ request.request_clasification }}
                  </span>
                  .
                </p>
                <span class="text-muted mb-2">
                  {{ request.created_at }}
                </span>
                <v-btn
                  outlined
                  color="indigo"
                  small
                  @click="goToScoresAmendFormReview"
                >
                  Revisar solicitud
                </v-btn>
                <v-divider class="mb-0"></v-divider>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </perfect-scrollbar>
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative;"
          v-if="scoresCorrectionAnswered.length > 0"
        >
          <template v-for="(request, i) in scoresCorrectionAnswered">
            <!--begin::Item-->
            <div
              :class="{ 'd-flex align-items-center mb-6': true }"
              v-bind:key="i"
            >
              <!--begin::Text-->
              <div class="d-flex flex-column justify-start align-start">
                <p class="text-dark mb-1 font-size-lg">
                  <span class="font-weight-bold">
                    {{ request.user_full_name }}
                  </span>
                  ha procesado tu solicitud de
                  <span class="text-lowercase font-weight-bold">
                    {{ request.request_clasification }}
                  </span>
                  .
                </p>
                <span class="text-muted mb-2">
                  {{ request.created_at }}
                </span>
                <v-btn
                  outlined
                  color="indigo"
                  small
                  @click="goToScoresAmendFormReview"
                >
                  Ver detalles
                </v-btn>
                <v-divider class="mb-0"></v-divider>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </perfect-scrollbar>
        <div
          class="d-flex flex-center text-center text-muted min-h-200px"
          v-else
        >
          ¡Todo al dia! <br />No hay notificaciones nuevas.
        </div>
      </b-tab>
      <!-- end::Alerts tab -->

      <!-- <b-tab>
        <perfect-scrollbar
          class="navi navi-hover scroll my-4"
          style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item, i) in list2">
            <a href="#" class="navi-item" v-bind:key="i">
              <div class="navi-link">
                <div class="navi-icon mr-2">
                  <i v-bind:class="item.icon"></i>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ item.title }}
                  </div>
                  <div class="text-muted">
                    {{ item.desc }}
                  </div>
                </div>
              </div>
            </a>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <div class="d-flex flex-center text-center text-muted min-h-200px">
          All caught up!<br />No new notifications.
        </div>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import requestRepository from "@/repositories/requestRepository";
import { mapGetters } from "vuex";

export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0,
      list1: [
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "primary",
          svg: process.env.BASE_URL + "media/svg/icons/Home/Library.svg",
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "warning",
          svg: process.env.BASE_URL + "media/svg/icons/Communication/Write.svg",
        },
      ],
      list2: [
        {
          title: "New report has been received",
          desc: "23 hrs ago",
          icon: "flaticon2-line-chart text-success",
        },
      ],
      scoresCorrectionRequested: [],
      scoresCorrectionAnswered: [],
    };
  },
  created() {
    this.connectToBroadcastingChannels();
  },
  mounted() {
    if (this.getUserType === "Superadministrador") {
      this.getScoresCorrectionPendingReviewRequests();
    } else if (
      this.getUserType === "Docente académico" ||
      this.getUserType === "Docente técnico"
    ) {
      this.getScoresCorrectionAnsweredRequests();
    }
  },
  destroyed() {
    if (this.getUserType === "Superadministrador") {
      try {
        window.Echo.leave("requests.pending-review");
      } catch (error) {
        console.log(error);
      }
    } else if (
      this.getUserType === "Docente académico" ||
      this.getUserType === "Docente técnico"
    ) {
      try {
        window.Echo.leave("requests.answered");
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    getScoresCorrectionPendingReviewRequests() {
      requestRepository
        .getRequestWithOldestRequestTracking()
        .then(({ data }) => {
          this.scoresCorrectionRequested = data.reverse();
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getScoresCorrectionAnsweredRequests() {
      requestRepository
        .getWhereLastTrackingIsAcceptedOrRejectedByUserId(
          this.currentUserPersonalInfo.id_user
        )
        .then(({ data }) => {
          this.scoresCorrectionAnswered = data.reverse();
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    goToScoresAmendFormReview({ idE }) {
      this.$router.push({
        path: "score_correction",
        query: {
          r: idE,
        },
      });
    },
    connectToBroadcastingChannels() {
      if (this.getUserType === "Superadministrador") {
        try {
          window.Echo.private("requests.pending-review").listen(
            "ScoresCorrectionAnswered",
            (response) => {
              console.log(response);
              // this.scoresCorrectionRequested.unshift(response.request);
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else if (
        this.getUserType === "Docente académico" ||
        this.getUserType === "Docente técnico"
      ) {
        try {
          window.Echo.private("requests.answered").listen(
            "ScoresCorrectionRequested",
            (response) => {
              console.log(response);
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["userType", "currentUserPersonalInfo"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    getUserType() {
      const { user_type: userType } = this.userType;
      return userType;
    },
  },
};
</script>
