<template>
  <div class="banner" v-if="visible">
    <h2 class="banner--title">{{ getTextMode }}</h2>
    <h2 class="banner--title__mobile">{{ getTextModeResponsive }}</h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DebugModeBanner',
  computed: {
    ...mapGetters(['userType']),
    visible() {
      return process.env.APP_ENV !== 'production';
    },
    getTextMode() {
      if (process.env.APP_ENV === 'development') {
        return 'Modo de prueba';
      } else {
        return 'Development mode';
      }
    },
    getTextModeResponsive() {
      if (process.env.APP_ENV === 'development') {
        return 'Pruebas';
      } else {
        return 'Dev';
      }
    },
    getUserType() {
      const { user_type: userType } = this.userType;
      return userType;
    },
  },
};
</script>

<style scoped>
.banner {
  position: fixed;
  top: 5%;
  left: 50%;
  padding: 8px 16px;
  z-index: 100;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff416c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff4b2b,
    #ff416c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
    #ff416c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: rgba(255, 75, 43, 1) 0px 2px 16px,
    rgba(255, 65, 108, 0.05) 0px 8px 32px !important;
}
.banner h2 {
  margin: 0;
  color: #fff;
}

.banner--title {
  display: none;
}
.banner--title__mobile {
  display: block;
}

@media (min-width: 768px) {
  .banner--title {
    display: block;
  }
  .banner--title__mobile {
    display: none;
  }
}
</style>
