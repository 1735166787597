<template>
  <v-dialog
    v-model="settingsDialogState"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-card class="py-3">
      <v-card-title dark color="blue-grey darken-4" class="elevation-0 pa-7">
        <v-row class="">
          <v-col class="d-flex align-center" cols="9"
            ><p class="mb-0 font-weight-bold text-h4">Ajustes</p></v-col
          >
          <v-col class="d-flex justify-end offcanvas-header" cols="3">
            <v-btn
              :loading="savingAnimation"
              :disabled="savingAnimation"
              color="red"
              class="ma-2 white--text"
              icon
              small
              @click="toggleSettingsModal()"
            >
              <v-icon dark> mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- ----------------------------- -->
      <v-card-text class="mb-5">
        <v-row class="mt-4">
          <p class="font-weight-medium text-h6 mb-0 ml-4">
            Año de origen para los datos:
          </p>
          <v-col col="12" sm="9" class="pb-0">
            <form-group name="originYear">
              <v-select
                :loading="refetchingUserStablishedYear"
                :disabled="!settings.setting"
                :items="availableYears"
                item-text="year"
                item-value="year"
                slot-scope="{ attrs }"
                v-bind="attrs"
                placeholder="Seleccione un año electivo"
                required
                outlined
                v-model="settings.setting"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" small v-if="index === 0">
                    <span class="font-weight-bold text-body-1 white--text">{{
                      item.year
                    }}</span>
                  </v-chip>
                </template></v-select
              >
            </form-group>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- --------------------- -->
      <v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
              <v-btn
                v-if="theresChangesToSave"
                class="success ml-3"
                @click="saveChanges()"
                ><span class="white--text font-weight-bold text-body-1"
                  >Guardar cambios</span
                ></v-btn
              >
            </v-scroll-x-transition>

            <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
              <p
                v-if="!theresChangesToSave"
                class="ml-3 font-weight-bold text-body-1 mb-0"
              >
                No hay cambios que guardar.
              </p>
            </v-scroll-x-transition>
          </v-col>
        </v-row>
      </v-card-actions>
      <!-- --------------------- -->
    </v-card>
  </v-dialog>
</template>

<script>
import settingsRepository from "@/repositories/settingsRepository";
import { mapGetters } from "vuex";

export default {
  name: "SettingsModal",

  mounted() {
    this.getAvailableYears();
  },

  props: {
    userStablishedYear: {
      type: Object,
    },
  },

  data() {
    return {
      settingsDialogState: false,
      isLoadingYears: false,
      availableYears: [],
      savingAnimation: false,
      thereIsChangesToSave: false,
      settings: {
        setting: undefined,
      },
      refetchingUserStablishedYear: false,
      //copy of the setting the user had when openned the settings modal
      originalUserSetting: undefined,
    };
  },

  watch: {
    userStablishedYear(newValue) {
      if (!!newValue) {
        this.settings.setting = this.userStablishedYear.setting;
      }
    },
  },

  methods: {
    toggleSettingsModal() {
      this.settingsDialogState = !this.settingsDialogState;
      this.reFechingAvailableYears();

      if (!this.settingsDialogState) {
        this.$emit("refresh");
      }
    },

    reFechingAvailableYears() {
      this.refetchingUserStablishedYear = true;
      settingsRepository
        .getUserSettings(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
          this.settings.setting = data.setting;
          this.originalUserSetting = data.setting;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title:
              "no se pudo obtener el año establecido previamente por el usuario",
          });
        })
        .finally(() => {
          this.refetchingUserStablishedYear = false;
        });
    },

    saveChanges() {
      this.savingAnimation = true;

      settingsRepository
        .saveUserSettings(this.currentUserPersonalInfo.id_user, this.settings)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title:
              "No ha sido posible actualizar el año de origen de la información",
          });
        })
        .finally(() => {
          this.toggleSettingsModal();
          this.savingAnimation = false;
        });
    },

    getAvailableYears() {
      this.isLoadingYears = false;
      settingsRepository
        .getAllYears()
        .then(({ data }) => {
          this.availableYears = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isLoadingYears = false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "userType"]),

    theresChangesToSave() {
      if (this.settings.setting == this.originalUserSetting) {
        return false;
      }
      return true;
    },
  },
};
</script>
