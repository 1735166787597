<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    class="max-width-280"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="btn btn-icon btn-clean btn-lg mr-1 my-auto pulse pulse-primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-badge :value="newNotification" color="red" dot>
          <v-icon color="blue">
            mdi-bell
          </v-icon>
        </v-badge>
        <span class="pulse-ring"></span>
      </div>
    </template>

    <v-card class="max-width-280">
      <v-card-title
        class="grey darken-4 accent-4 px-10 pt-8 pb-4 d-flex flex-row justify-center align-center"
      >
        <div class="text-h5 white--text">
          Notificaciones
        </div>

        <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2 mr-0"
          @click="getScoresCorrectionPendingReviewRequests"
        >
          {{ getNumOfNotifications }}
        </span>
      </v-card-title>

      <v-tabs
        v-model="tabIndex"
        dark
        background-color="grey darken-4 accent-4"
        show-arrows
        height="35"
        centered
      >
        <v-tabs-slider color="teal accent-3"></v-tabs-slider>

        <v-tab>
          Correcciones de nota
        </v-tab>
        <v-tab>
          Extensiones de perfil
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabIndex">
        <!-- begin::Scores Correction List Tab -->
        <v-tab-item>
          <perfect-scrollbar
            class="scroll px-5 py-3"
            style="max-height: 40vh; position: relative;"
            v-if="filteredData.length > 0"
          >
            <template v-for="(request, i) in filteredData">
              <div :key="i">
                <v-list-item
                  class="list-item px-0"
                  color="grey lighten-1"
                  bg
                  three-line
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-dark mb-1 font-size-lg text-wrap"
                    >
                      <span class="font-weight-bold">
                        {{ request.user_full_name }}
                      </span>
                      ha solicitado una
                      <span class="text-lowercase font-weight-bold">
                        {{ request.request_clasification }}
                      </span>
                      .
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text--grey text--darken-3 mb-2 text-wrap"
                    >
                      {{ request.created_at }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-btn
                        outlined
                        color="grey darken-4"
                        small
                        @click="goToScoresAmendFormReview(request)"
                      >
                        Revisar solicitud
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  class="mb-0 mt-1"
                  v-if="i < filteredData.length - 1"
                  :key="i"
                ></v-divider>
              </div>
            </template>
          </perfect-scrollbar>
          <div
            class="d-flex flex-center text-center text-muted min-h-200px"
            v-else
          >
            ¡Todo al dia! <br />No hay notificaciones nuevas.
          </div>
        </v-tab-item>
        <!-- end::Scores Correction List Tab -->
        <!-- begin::Limit Date Extensions List Tab -->
        <v-tab-item>
          <perfect-scrollbar
            class="scroll px-5 py-3"
            style="max-height: 40vh; position: relative;"
            v-if="filteredLimitDateExtensions.length > 0"
          >
            <template v-for="(request, i) in filteredLimitDateExtensions">
              <div :key="i">
                <v-list-item
                  class="list-item px-0"
                  color="grey lighten-1"
                  bg
                  three-line
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-dark mb-1 font-size-lg text-wrap"
                    >
                      <span class="font-weight-bold">
                        {{ request.user_full_name }}
                      </span>
                      ha solicitado una
                      <span class="text-lowercase font-weight-bold">
                        {{ request.request_clasification }}
                      </span>
                      .
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text--grey text--darken-3 mb-2 text-wrap"
                    >
                      {{ request.created_at }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-btn
                        outlined
                        color="grey darken-4"
                        small
                        @click="goToLimitDateExtensionReview(request)"
                      >
                        Revisar solicitud
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  class="mb-0 mt-1"
                  v-if="i < filteredData.length - 1"
                  :key="i"
                ></v-divider>
              </div>
            </template>
          </perfect-scrollbar>
          <div
            class="d-flex flex-center text-center text-muted min-h-200px"
            v-else
          >
            ¡Todo al dia! <br />No hay notificaciones nuevas.
          </div>
        </v-tab-item>
        <!-- end::Limit Date Extensions List Tab -->
      </v-tabs-items>
    </v-card>
  </v-menu>
</template>

<script>
import requestRepository from "@/repositories/requestRepository";
import limitDateExtensionRepository from "@/repositories/limitDateExtensionRepository";
import { mapGetters } from "vuex";

export default {
  name: "CoordinatorDropdownMenu",
  data() {
    return {
      menu: false,
      tabIndex: null,
      scoresCorrectionRequested: [],
      limitDateExtensionsRequested: [],
      newNotification: false,
      userTypesAllowedToSeeInformaticaEducativa: ["Coordinación técnica"],
      informaticaEducativaStr: "Informática educativa",
    };
  },
  created() {
    // TODO: Because of the cnage made related with the db schemas implementation this is not longer working, this might be updated with a proper implementation of sockets

    // this.connectToBroadcastingChannels();
  },
  mounted() {
    // TODO: Because of the cnage made related with the db schemas implementation this is not longer working, this might be updated with a proper implementation of sockets
    // this.getScoresCorrectionPendingReviewRequests();
    // this.getLimitDateExtensionPendingReviewRequests();
  },
  destroyed() {
    // TODO: Because of the cnage made related with the db schemas implementation this is not longer working, this might be updated with a proper implementation of sockets

    // this.disconnectToBroadcastingChannels();
  },
  methods: {
    getScoresCorrectionPendingReviewRequests() {
      requestRepository
        .getRequestWithOldestRequestTracking()
        .then(({ data }) => {
          this.scoresCorrectionRequested = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getLimitDateExtensionPendingReviewRequests() {
      limitDateExtensionRepository
        .getLimitDateExtensionRequestWithOldestRequestTracking()
        .then(({ data }) => {
          this.limitDateExtensionsRequested = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    goToScoresAmendFormReview({ idE }) {
      this.$router.push({
        path: "score_correction",
        query: {
          r: idE,
        },
      });
    },
    goToLimitDateExtensionReview({ idE }) {
      if (
        this.$router.history.current.path === "/requests" &&
        this.$router.history.current.query.lde === idE
      ) {
        return;
      } else {
        this.$router.push({
          path: "requests",
          query: {
            lde: idE,
          },
        });
      }
    },
    connectToBroadcastingChannels() {
      try {
        // TODO: Remove commented code, and replace it with the another socker implementation
        // window.Echo.private("requests.pending-review").listen(
        //   "ScoresCorrectionRequested",
        //   (response) => {
        //     this.scoresCorrectionRequested.unshift(response.request);
        //     this.newNotification = true;
        //   }
        // );
        // window.Echo.private("limit-date-extensions.pending-review").listen(
        //   "SubEvaluationLimitDateExtensionRequested",
        //   (response) => {
        //     this.limitDateExtensionsRequested.unshift(response.request);
        //     this.newNotification = true;
        //   }
        // );
      } catch (error) {
        console.error(error);
      }
    },
    disconnectToBroadcastingChannels() {
      try {
        // TODO: Remove commented code, and replace it with the another socker implementation
        // window.Echo.leave("requests.pending-review");
        // window.Echo.leave("limit-date-extensions.pending-review");
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(["userType", "currentUserPersonalInfo"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    getUserType() {
      const { user_type: userType } = this.userType;
      return userType;
    },
    filteredData() {
      return this.scoresCorrectionRequested.filter((item) => {
        if (item.subject == this.informaticaEducativaStr) {
          return this.userTypesAllowedToSeeInformaticaEducativa.some(
            (userType) => userType === this.getUserType
          );
        }

        return item.user_types_allowed_to_see_notification.some(
          (userType) => userType === this.getUserType
        );
      });
    },
    filteredLimitDateExtensions() {
      return this.limitDateExtensionsRequested.filter((item) => {
        if (item.subject == this.informaticaEducativaStr) {
          return this.userTypesAllowedToSeeInformaticaEducativa.some(
            (userType) => userType === this.getUserType
          );
        }

        return item.user_types_allowed_to_see_notification.some(
          (userType) => userType === this.getUserType
        );
      });
    },
    getNumOfNotifications() {
      return this.filteredData.length + this.filteredLimitDateExtensions.length;
    },
  },
  watch: {
    menu(newValue, oldValue) {
      // Al abrirse el menu el badge se oculta
      if (newValue) {
        this.newNotification = false;
      }
    },
  },
};
</script>

<style scoped>
.text-wrap {
  white-space: normal !important;
}
</style>
